import request from './request'

const api = {
	// 查看指定管理员详情
	info: '/system/Admin/read',
	// 列表
	lists: '/system/Admin/getPageList',
	// 添加
	add: '/system/Admin/create',
	// 获取角色列表
	rolelist: '/system/Role/getPageList',
	// 编辑
	edit: '/system/Admin/update',
	// 删除
	dele: '/system/Admin/delete',
	// 统计
	statistics: '/gh/Trade/statistics',
	// 重置密码
	resetPassword: '/user/User/resetPassword',
	// 获取系统配置
	getConfig: '/system/config/getConfig',
	
}

export {
	request,
	api
}
